import React, { useState } from 'react';

function Vragenlijst() {
  const [formData, setFormData] = useState({
    vraag1: '',
    vraag2: '',
    vraag3: '',
    vraag4: '',
    vraag5: '',
    vraag6: '',
    vraag7: '',
    vraag8: '',
    vraag9: '',
    vraag10: '',
  });

  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tinnitusbehandeling.online/api/vragenlijst/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();

              // Maximale scores voor normalisatie
      const maxScores = {
        total_score: 104, // voorbeeld totale maximale score
        factor1_score: 20,
        factor2_score: 14,
        factor3_score: 16,
        factor4_score: 8,
        factor5_score: 12,
        factor6_score: 10,
        factor7_score: 10,
      };


        
      alert(
        `Vragenlijst verzonden! Je totale score is: ${((data.total_score / maxScores.total_score) * 100).toFixed(2)}%. Scores:
        - Emoties: ${((data.factor1_score / maxScores.factor1_score) * 100).toFixed(2)}%
        - Tinnitus invloed op participatie: ${((data.factor2_score / maxScores.factor2_score) * 100).toFixed(2)}%
        - Cognitieve distress: ${((data.factor3_score / maxScores.factor3_score) * 100).toFixed(2)}%
        - Somatische klachten: ${((data.factor4_score / maxScores.factor4_score) * 100).toFixed(2)}%
        - Slapen: ${((data.factor5_score / maxScores.factor5_score) * 100).toFixed(2)}%
        - Hyperacusis: ${((data.factor6_score / maxScores.factor6_score) * 100).toFixed(2)}%
        - Selectie: ${((data.factor7_score / maxScores.factor7_score) * 100).toFixed(2)}%`
      );
      
      } else {
        alert('Er is iets misgegaan bij het verzenden.');
      }
    } catch (error) {
      console.error(error);
      alert('Er is een fout opgetreden.');
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* Vraag 1 */}
      <div>
        <label htmlFor="vraag1">1 Soms kan ik het oorsuizen negeren, ook als het er is:</label>
        <select
          id="vraag1"
          name="vraag1"
          value={formData.vraag1}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      {/* Vraag 2 */}
      <div>
        <label htmlFor="vraag2">2 Ik ben tevreden met mijn huidige leven:</label>
        <select
          id="vraag2"
          name="vraag2"
          value={formData.vraag2}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
        {/* Vraag 3 */}
        <div>
        <label htmlFor="vraag3">3 Het is oneerlijk dat ik onder het oorsuizen moet lijden:</label>
        <select
          id="vraag3"
          name="vraag3"
          value={formData.vraag3}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      
      {/* Vraag 4 */}
      <div>
        <label htmlFor="vraag4">4 Ik word ’s nachts vaker wakker door mijn oorsuizen:</label>
        <select
          id="vraag4"
          name="vraag4"
          value={formData.vraag4}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 5 */}
      <div>
        <label htmlFor="vraag5">5 Ik ben me bewust van het oorsuizen vanaf het moment dat ik in slaap val:</label>
        <select
          id="vraag5"
          name="vraag5"
          value={formData.vraag5}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 6 */}
      <div>
        <label htmlFor="vraag6">6 Je houding ten opzichte van het oorsuizen heeft geen invloed:</label>
        <select
          id="vraag6"
          name="vraag6"
          value={formData.vraag6}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 7 */}
      <div>
        <label htmlFor="vraag7">7 Meestal is het oorsuizen vrij zacht:</label>
        <select
          id="vraag7"
          name="vraag7"
          value={formData.vraag7}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      
      {/* Vraag 8 */}
      <div>
        <label htmlFor="vraag8">8 Ik ben bang dat het oorsuizen me een zenuwinzinking bezorgt:</label>
        <select
          id="vraag8"
          name="vraag8"
          value={formData.vraag8}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 9 */}
      <div>
        <label htmlFor="vraag9">9 Door het oorsuizen kost het me moeite te zeggen waar geluiden vandaag komen:</label>
        <select
          id="vraag9"
          name="vraag9"
          value={formData.vraag9}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 10 */}
      <div>
        <label htmlFor="vraag10">10 De manier waarop het oorsuizen klinkt, is echt onprettig:</label>
        <select
          id="vraag10"
          name="vraag10"
          value={formData.vraag10}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 11 */}
      <div>
        <label htmlFor="vraag11">11 Ik heb het gevoel dat ik nooit aan het oorsuizen kan ontsnappen:</label>
        <select
          id="vraag11"
          name="vraag11"
          value={formData.vraag11}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      {/* Vraag 12 */}
      <div>
        <label htmlFor="vraag12">12 Door het oorsuizen word ik ’s morgens vroeger wakker:</label>
        <select
          id="vraag12"
          name="vraag12"
          value={formData.vraag12}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
        {/* Vraag 13 */}
        <div>
        <label htmlFor="vraag13">13  Ik maak me er zorgen over of ik dit probleem voor altijd zal kunnen verdragen:</label>
        <select
          id="vraag13"
          name="vraag13"
          value={formData.vraag13}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      
      {/* Vraag 14 */}
      <div>
        <label htmlFor="vraag14">14 Vanwege het oorsuizen is het moeilijker om naar meer mensen tegelijkertijd te luisteren:</label>
        <select
          id="vraag14"
          name="vraag14"
          value={formData.vraag14}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 15 */}
      <div>
        <label htmlFor="vraag15">15 Het oorsuizen is meestal luid:</label>
        <select
          id="vraag15"
          name="vraag15"
          value={formData.vraag15}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 16 */}
      <div>
        <label htmlFor="vraag16">16 Vanwege het oorsuizen ben ik bang dat er lichamelijk iets ernstig mis is met mij:</label>
        <select
          id="vraag16"
          name="vraag16"
          value={formData.vraag16}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 17 */}
      <div>
        <label htmlFor="vraag17">17 Als het oorsuizen blijft, is mijn leven niet meer de moeite waard:</label>
        <select
          id="vraag17"
          name="vraag17"
          value={formData.vraag17}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      
      {/* Vraag 18 */}
      <div>
        <label htmlFor="vraag18">18 Ik ben aan zelfvertrouwen verloren door het oorsuizen:</label>
        <select
          id="vraag18"
          name="vraag18"
          value={formData.vraag18}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 19 */}
      <div>
        <label htmlFor="vraag19">19 Ik wou dat iemand begreep wat voor een probleem dit is:</label>
        <select
          id="vraag19"
          name="vraag19"
          value={formData.vraag19}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 20 */}
      <div>
        <label htmlFor="vraag20">20 Het oorsuizen leidt mij af, wat ik ook doe:</label>
        <select
          id="vraag20"
          name="vraag20"
          value={formData.vraag20}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 21 */}
      <div>
        <label htmlFor="vraag21">21 Je kunt maar heel weinig doen om het oorsuizen te leren verdragen:</label>
        <select
          id="vraag21"
          name="vraag21"
          value={formData.vraag21}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 22 */}
      <div>
        <label htmlFor="vraag22">22 Het oorsuizen bezorgt mij soms oorpijn of hoofdpijn:</label>
        <select
          id="vraag22"
          name="vraag22"
          value={formData.vraag22}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      {/* Vraag 23 */}
      <div>
        <label htmlFor="vraag23">23 Als ik me neerslachtig en somber voel, lijkt het oorsuizen erger:</label>
        <select
          id="vraag23"
          name="vraag23"
          value={formData.vraag23}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
        {/* Vraag 24 */}
        <div>
        <label htmlFor="vraag24">24  Ik ben sneller geïrriteerd in de omgang met familie en vrienden als gevolg van het oorsuizen:</label>
        <select
          id="vraag24"
          name="vraag24"
          value={formData.vraag24}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      
      {/* Vraag 25 */}
      <div>
        <label htmlFor="vraag25">25 Door het oorsuizen zijn de spieren in mijn hoofd en nek gespannen:</label>
        <select
          id="vraag25"
          name="vraag25"
          value={formData.vraag25}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 26 */}
      <div>
        <label htmlFor="vraag26">26 Door het oorsuizen klinken de stemmen van andere mensen voor mij vervormd:</label>
        <select
          id="vraag26"
          name="vraag26"
          value={formData.vraag26}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 27 */}
      <div>
        <label htmlFor="vraag27">27 Het zal verschrikkelijk zijn als dit oorsuizen nooit overgaat:</label>
        <select
          id="vraag27"
          name="vraag27"
          value={formData.vraag27}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 28 */}
      <div>
        <label htmlFor="vraag28">28 Ik maak me er zorgen over dat het oorsuizen mijn lichamelijke gezondheid kan schaden:</label>
        <select
          id="vraag28"
          name="vraag28"
          value={formData.vraag28}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      
      {/* Vraag 29 */}
      <div>
        <label htmlFor="vraag29">29 Het oorsuizen lijkt dwars door mijn hoofd te gaan:</label>
        <select
          id="vraag29"
          name="vraag29"
          value={formData.vraag29}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 30 */}
      <div>
        <label htmlFor="vraag30">30 Bijna al mijn problemen worden door het oorsuizen veroorzaakt:</label>
        <select
          id="vraag30"
          name="vraag30"
          value={formData.vraag30}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 31 */}
      <div>
        <label htmlFor="vraag31">31 Het slapen is mijn grootste probleem:</label>
        <select
          id="vraag31"
          name="vraag31"
          value={formData.vraag31}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 32 */}
      <div>
        <label htmlFor="vraag32">32 Het is de manier waarop je over het oorsuizen denkt, NIET het oorsuizen zelf wat je van streek maakt:</label>
        <select
          id="vraag32"
          name="vraag32"
          value={formData.vraag32}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      {/* Vraag 33 */}
      <div>
        <label htmlFor="vraag33">33 Door het oorsuizen heb ik meer moeite om een gesprek te volgen:</label>
        <select
          id="vraag33"
          name="vraag33"
          value={formData.vraag33}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
        {/* Vraag 34 */}
        <div>
        <label htmlFor="vraag34">34  Ik vind het moeilijk om te ontspannen vanwege het oorsuizen:</label>
        <select
          id="vraag34"
          name="vraag34"
          value={formData.vraag34}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      
      {/* Vraag 35 */}
      <div>
        <label htmlFor="vraag35">35 Mijn oorsuizen is vaak zo erg dat ik het niet kan negeren:</label>
        <select
          id="vraag35"
          name="vraag35"
          value={formData.vraag35}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 36 */}
      <div>
        <label htmlFor="vraag36">36 Het kost me meer tijd om in slaap te vallen vanwege het oorsuizen:</label>
        <select
          id="vraag36"
          name="vraag36"
          value={formData.vraag36}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 37 */}
      <div>
        <label htmlFor="vraag37">37 Ik word soms heel boos als ik nadenk over het oorsuizen:</label>
        <select
          id="vraag37"
          name="vraag37"
          value={formData.vraag37}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 38 */}
      <div>
        <label htmlFor="vraag38">38 Door het oorsuizen vind ik het moeilijker om te telefoneren:</label>
        <select
          id="vraag38"
          name="vraag38"
          value={formData.vraag38}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      
      {/* Vraag 39 */}
      <div>
        <label htmlFor="vraag39">39  Ik voel me sneller somber door het oorsuizen:</label>
        <select
          id="vraag39"
          name="vraag39"
          value={formData.vraag39}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 40 */}
      <div>
        <label htmlFor="vraag40">40 Ik kan het oorsuizen vergeten als ik iets aan het doen ben dat me interesseert:</label>
        <select
          id="vraag40"
          name="vraag40"
          value={formData.vraag40}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 41 */}
      <div>
        <label htmlFor="vraag41">41 Door het oorsuizen ben ik haast niet meer tegen het leven opgewassen:</label>
        <select
          id="vraag41"
          name="vraag41"
          value={formData.vraag41}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 42 */}
      <div>
        <label htmlFor="vraag42">42 Ik heb me altijd al zorgen gemaakt over problemen met mijn oren:</label>
        <select
          id="vraag42"
          name="vraag42"
          value={formData.vraag42}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      {/* Vraag 43 */}
      <div>
        <label htmlFor="vraag43">43  Ik denk er vaak over na of het oorsuizen ooit weg zal gaan:</label>
        <select
          id="vraag43"
          name="vraag43"
          value={formData.vraag43}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
        {/* Vraag 44 */}
        <div>
        <label htmlFor="vraag44">44  Ik kan me voorstellen dat ik met oorsuizen om kan gaan:</label>
        <select
          id="vraag44"
          name="vraag44"
          value={formData.vraag44}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      
      {/* Vraag 45 */}
      <div>
        <label htmlFor="vraag45">45 Het oorsuizen wordt nooit minder:</label>
        <select
          id="vraag45"
          name="vraag45"
          value={formData.vraag45}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 46 */}
      <div>
        <label htmlFor="vraag46">46 Een sterker iemand zou dit probleem misschien makkelijker accepteren:</label>
        <select
          id="vraag46"
          name="vraag46"
          value={formData.vraag46}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 47 */}
      <div>
        <label htmlFor="vraag47">47 Ik ben slachtoffer van mijn oorsuizen:</label>
        <select
          id="vraag47"
          name="vraag47"
          value={formData.vraag47}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 48 */}
      <div>
        <label htmlFor="vraag48">48 Het oorsuizen heeft mijn concentratie aangetast:</label>
        <select
          id="vraag48"
          name="vraag48"
          value={formData.vraag48}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      
      {/* Vraag 49 */}
      <div>
        <label htmlFor="vraag49">49  Het oorsuizen is één van die problemen in het leven waarmee je moet leren leven:</label>
        <select
          id="vraag49"
          name="vraag49"
          value={formData.vraag49}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 50 */}
      <div>
        <label htmlFor="vraag50">50 Vanwege het oorsuizen kan ik niet van de radio of televisie genieten:</label>
        <select
          id="vraag50"
          name="vraag50"
          value={formData.vraag50}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 51 */}
      <div>
        <label htmlFor="vraag51">51 Het oorsuizen leidt soms tot zware hoofdpijn:</label>
        <select
          id="vraag51"
          name="vraag51"
          value={formData.vraag51}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>
      {/* Vraag 52 */}
      <div>
        <label htmlFor="vraag52">52 Ik ben altijd een lichte slaper geweest:</label>
        <select
          id="vraag52"
          name="vraag52"
          value={formData.vraag52}
          onChange={handleChange}
        >
          <option value="">Selecteer een antwoord</option>
          <option value="waar">Waar</option>
          <option value="deels waar">Deels waar</option>
          <option value="niet waar">Niet waar</option>
        </select>
      </div>

      


      <button type="submit">Verzenden</button>
    </form>
  );
}

export default Vragenlijst;




