import React from 'react';

function MyAudioPlayer() {
    return (
        <div>
            <h1>My Custom Audio Player</h1>
            <audio controls>
                <source src="./assets/Body Scan.m4a" type="audio/mpeg" />
                Your browser does not support the audio element.
                
            </audio>
        </div>
    );
}

export default MyAudioPlayer;