import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';
import '../../HomePage.css';


import ReactPlayer from 'react-player';

const MyPlayer = () => {
  return (
      <div>
         
          <ReactPlayer controls={true} url={"https://www.youtube.com/watch?v=TnsCsR2wDdk&t=321s"} height="400" width="500" />
      </div>
  );
}
const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);
    

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  

  return (
    <>
      <div className="containereen">
         

       <p className='Tinnitusbehandeling'>
        De tinnitus behandeling online is een initatief van een aantal enthousiaste professionals. We werken met hernieuwde inzichten om tinnitus daadwerkelijk te behandelen en om te buigen. Er zijn verschillende ontwikkelingen
        en nieuwe inzichten die aantonen dat tinnitus geen lokaal auditief probleem is maar een "brein" probleem. En het brein is te trainen! 
        
        </p>
      
      
    </div>
    
    <div className="containertwee">
        <strong>Tinnitus Behandeling Online</strong>
        
        <div className="app-picture">
          <img alt="boris" src="./assets/TBO.png"/>
      
       </div>
    </div>
    
    <div>
      <h2>Doel </h2>
      <p>Ons doel is om met een wetenschappelijk onderbouwde, maar ook pragmatische aanpak tinnitusklachten te behandelen <br></br>op een holistische en integrale manier; de behandeling kan bestaan uit online begeleiding, maar kan ook plaatshebben <br></br>in de praktijk, individueel of in groepsverband. We hebben reeds veel mensen met succes begeleid en hopen met dit platform <br></br>een groter publiek te bereiken.
      <div className="app-picture">
          <img alt="boris" src="./assets/ospan-ali-Dh-Ip3YKAEM-unsplash.jpg"/>
      
       </div>
<br></br><br></br>Wat is tinnitus?

<MyPlayer /> {/* MyPlayer is used here */}



<br></br>We sluiten aan bij deze aanpak, waarbij wij iets minder ingaan op het maskeren van het geluid (geluidstherapie) en meer <br></br>ingaan op de somatische (lichamelijke) kant van tinnitus.

Wij richten ons op somatosensorische tinnitus, maar ook “bothersome tinnitus” krijgt <br></br>volop aandacht in de cursus en in de training. Als je benieuwd bent naar onze werkwijze en als je wil weten of je in aanmerking komt <br></br>voor een consult orofaciale therapie in de praktijk, dan wel voor de training meld je dan aan voor het online assessment. Je kunt je verhaal dan delen <br></br>en je krijgt direct bruikbare tips. </p>



      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Online assessment</h3>
            <h5>99.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >

          <button className='button' type='submit'>
            Checkout
          </button>
        </form>
      </section>
    </div>
    </>
  );
};

export default HomePage;