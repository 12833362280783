import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';



const Cursus = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      
      <div class="container">
        
        <b><p>Cursus</p></b>
        <p>de cursus bestaat uit 10 weken die volledig online is te volgen; er worden vragenlijsten afgenomen en er worden opdrachten gegeven. Kortom de cursus is opgezet om je te informeren maar ook om je actief aan het werk te zetten. 
        Er is een cursusleider waar je regelmatig overleg mee hebt. Je kunt al een kijkje nemen op de "Preview": zie boven menu. Bij module 1 is het "spoorboekje" in te zien en je kunt ook al een webinar inzien.
        Als je je aanmeldt via onderstaande knop krijg je je inloggegevens en kun je direct aan de slag!</p>

    </div>

    
    
      
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Cursus</h3>
            <h5>199.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          <button className='button' type='submit'>
            Bestel
          </button>
        </form>
      </section>
    </div>
  );
};

export default Cursus;