// src/components/structure/RenderNavigation.js
import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthData } from '../../AuthContext';
import HomePage from '../pages/HomePage';

import MainLayout from '../../layouts/MainLayout'; // Ensure this path is correct

import Orofaciaallayout from '../../layouts/Orofaciaallayout'; // Ensure this path is correct
import KaakfysiotherapiePage from '../pages/KaakfysiotherapiePage'; // Ensure this path is correct
import CraftaPage from '../pages/CraftaPage'; // Ensure this path is correct
import VestibulairetrainingPage from '../pages/VestibulairetrainingPage'; // Ensure this path is correct
import Traininglayout from '../../layouts/Traininglayout'; // Ensure this path is correct
import CursusPage from '../pages/CursusPage'; // Ensure this path is correct
import OnlineassessmentPage from '../pages/OnlineassessmentPage'; // Ensure this path is correct
import EbookPage from '../pages/EbookPage'; // Ensure this path is correct
import InhoudsopgavePage from '../pages/InhoudsopgavePage'; // Ensure this path is correct
import Dashboardlayout from '../../layouts/Dashboardlayout'; // Ensure this path is correct
import HerkenningPage from '../pages/HerkenningPage'; // Ensure this path is correct
import AnPage1 from '../pages/AnPage1'; // Ensure this path is correct
import AnPage2 from '../pages/AnPage2'; // Ensure this path is correct
import TqPage from '../pages/TqPage'; // Ensure this path is correct
import Previewlayout from '../../layouts/Previewlayout'; // Ensure this path is correct
import HerkenningprePage from '../pages/HerkenningprePage'; // Ensure this path is correct
import AnPage1pre from '../pages/AnPage1pre'; // Ensure this path is correct
import AnPage2pre from '../pages/AnPage2pre'; // Ensure this path is correct
import TqprePage from '../pages/TqprePage'; // Ensure this path is correct
import KaakfysioPage from '../pages/KaakfysioPage'; // Ensure this path is correct
import StressPage from '../pages/StressPage'; // Ensure this path is correct
import Hs2Page from '../pages/Hs2Page'; // Ensure this path is correct
import Hs3Page from '../pages/Hs3Page'; // Ensure this path is correct
import RidderPage from '../pages/RidderPage'; // Ensure this path is correct
import NeuroplasticityPage from '../pages/NeuroplasticityPage'; // Ensure this path is correct
import BilateralPage from '../pages/BilateralPage'; // Ensure this path is correct
import AdemhalingPage from '../pages/AdemhalingPage'; // Ensure this path is correct
import Hs4Page from '../pages/Hs4Page'; // Ensure this path is correct
import JoeyPage from '../pages/JoeyPage'; // Ensure this path is correct
import HuberPage from '../pages/HuberPage'; // Ensure this path is correct
import BodyscanPage from '../pages/BodyscanPage'; // Ensure this path is correct
import Hs5Page from '../pages/Hs5Page'; // Ensure this path is correct
import TrainbaarPage from '../pages/TrainbaarPage';
import SquatPage from '../pages/SquatPage'; // Ensure this path is correct
import MTPage from '../pages/MTPage'; // Ensure this path is correct
import Hs6Page from '../pages/Hs6Page'; // Ensure this path is correct
import FilosofePage from '../pages/FilosofePage'; // Ensure this path is correct
import IntentioneelPage from '../pages/IntentioneelPage'; // Ensure this path is correct

import SpoorboekjePage from '../pages/SpoorboekjePage'; // Ensure this path is correct
import LoginPage from '../pages/LoginPage';

import '../../index.css'



export const RenderMenu = () => {
  const { user } = AuthData();
  const navigate = useNavigate();

  return (
    <nav>
      <ul className="menu">
        <li><a href="/" onClick={(e) => {e.preventDefault(); navigate('/');}}>Home</a></li>
        
        <li><a href="/orofaciaal" onClick={(e) => {e.preventDefault(); navigate('/orofaciaal');}}>Kaakfysiotherapie</a></li>
        <li><a href="/training" onClick={(e) => {e.preventDefault(); navigate('/training');}}>Training</a></li>
        <li><a href="/preview" onClick={(e) => {e.preventDefault(); navigate('/preview');}}>Preview</a></li>
        
        {!user.isAuthenticated && <li><a href="/login" onClick={(e) => {e.preventDefault(); navigate('/login');}}>Login</a></li>}
        {user.isAuthenticated && <li><a href="/dashboard" onClick={(e) => {e.preventDefault(); navigate('/dashboard');}}>Dashboard</a></li>}
      </ul>
    </nav>
  );
};


export const RenderRoutes = () => {
  const { user } = AuthData();

  return (
    <Routes>
      <Route index element={<HomePage />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="orofaciaal" element={<Orofaciaallayout />}>
          <Route path="kaakfysiotherapie" element={<KaakfysiotherapiePage />} />
          <Route path="crafta" element={<CraftaPage />} />
          <Route path="vestibulairetraining" element={<VestibulairetrainingPage />} />
        </Route>

        <Route path="training" element={<Traininglayout />}>
          <Route path="ebook" element={<EbookPage />} />
          <Route path="inhoudsopgave" element={<InhoudsopgavePage />} />
          <Route path="onlineassessment" element={<OnlineassessmentPage />} />
          <Route path="cursus" element={<CursusPage />} />
        </Route>

        <Route path="preview" element={<Previewlayout />}>
          <Route path="herkenningpre" element={<HerkenningprePage />} />
          <Route path="an1pre" element={<AnPage1pre />} />
          <Route path="an2pre" element={<AnPage2pre />} />
          <Route path="tqpre" element={<TqprePage />} />
        </Route>

        <Route path="dashboard" element={<Dashboardlayout />}>
          <Route path="herkenning" element={<HerkenningPage />} />
          <Route path="an1" element={<AnPage1 />} />
          <Route path="an2" element={<AnPage2 />} />
          <Route path="tq" element={<TqPage />} />
          <Route path="kaakfysio" element={<KaakfysioPage />} />
          <Route path="stress" element={<StressPage />} />
          <Route path="hs2" element={<Hs2Page />} />
          <Route path="ridder" element={<RidderPage />} />
          <Route path="neuroplasticity" element={<NeuroplasticityPage />} />
          <Route path="bilateral" element={<BilateralPage />} />
          <Route path="ademhaling" element={<AdemhalingPage />} />
          <Route path="hs3" element={<Hs3Page />} />
          <Route path="huber" element={<HuberPage />} />
          <Route path="bodyscan" element={<BodyscanPage />} />
          <Route path="hs4" element={<Hs4Page />} />
          <Route path="joey" element={<JoeyPage />} />
          <Route path="hs5" element={<Hs5Page />} />
          <Route path="trainbaar" element={<TrainbaarPage />} />
          <Route path="squat" element={<SquatPage />} />
          <Route path="mt" element={<MTPage />} />
          <Route path="hs6" element={<Hs6Page />} />
          <Route path="filosofe" element={<FilosofePage />} />
          <Route path="intentioneel" element={<IntentioneelPage />} />
          
          <Route path="spoorboekje" element={<SpoorboekjePage />} />

        </Route>
      </Route>

      <Route path="/login" element={!user.isAuthenticated ? <LoginPage /> : <Navigate to="/" />} />
      <Route path="/dashboard" element={user.isAuthenticated ? <Dashboardlayout /> : <Navigate to="/login" />} />
    </Routes>
  );
};
