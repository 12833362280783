import React from 'react';
import ReactPlayer from 'react-player';

function MyPlayer() {
    return (
        <div>
            <h1>My Custom Player</h1>
            <ReactPlayer 
                controls={true} 
                url="https://www.youtube.com/watch?v=4xhd8GMGsQU" 
                height="400px" 
                width="500px" 
            />
        </div>
    );
}

export default MyPlayer;
